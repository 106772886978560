<div class="popup-schliessen" (click)="popupSchliessen()">
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.22578 13.8248L0.175781 12.7748L5.95078 6.9998L0.175781 1.2248L1.22578 0.174805L7.00078 5.9498L12.7758 0.174805L13.8258 1.2248L8.05078 6.9998L13.8258 12.7748L12.7758 13.8248L7.00078 8.0498L1.22578 13.8248Z"
      fill="#262231"
    ></path>
  </svg>
</div>
<div class="popup-content">
  <div class="container">
    <div class="row">
      <div class="column">
        <h4>{{ modul?.name }}</h4>
      </div>
    </div>
    <div class="row" style="margin-top: 36px; margin-bottom: 12px">
      <div class="column">
        <span>{{ modul?.leitfrage }}</span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <button class="worxplorer-button" (click)="bearbeiten()">Bearbeiten</button>
      </div>
    </div>
  </div>
</div>
