<h2 [ngClass]="{ vorhanden: username != null }" style="margin-left: 24px !important">
  Hey<span>{{ username && username.trim() ? ' ' : '' }}</span
  ><span [ngClass]="{ 'text-unterstrichen': usernameBetonen }">{{
    username && username.trim() ? username : ''
  }}</span
  ><span>,</span>
</h2>

<wichtigste-frage></wichtigste-frage>
<erledigte-themen [datenAnzeigen]="datenAnzeigen"></erledigte-themen>
