export enum LogLevel {
  ERROR = 0,
  WARN = 1,
  INFO = 2,
  LOG = 3,
  DEBUG = 4,
}

export class ConsoleLogger {
  public constructor(
    private readonly className: string,
    private readonly _logLevel: LogLevel = LogLevel.INFO,
  ) {}

  public error(message: string, ...args: any[]): void {
    if (this._logLevel >= LogLevel.ERROR) {
      console.error(`${this.className} [ERROR]: ${message}`, ...args);
    }
  }

  public warn(message: string, ...args: any[]): void {
    if (this._logLevel >= LogLevel.WARN) {
      console.warn(`${this.className} [WARN]: ${message}`, ...args);
    }
  }

  public info(message: string, ...args: any[]): void {
    if (this._logLevel >= LogLevel.INFO) {
      console.info(`${this.className} [INFO]: ${message}`, ...args);
    }
  }

  public log(message: string, ...args: any[]): void {
    if (this._logLevel >= LogLevel.LOG) {
      console.log(`${this.className} [LOG]: ${message}`, ...args);
    }
  }

  public debug(message: string, ...args: any[]): void {
    if (this._logLevel >= LogLevel.DEBUG) {
      console.debug(`${this.className} [DEBUG]: ${message}`, ...args);
    }
  }
}
