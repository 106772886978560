import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
import { AuthService } from '../../../services/auth/auth.service';

@Component({
  selector: 'app-notiz-karte',
  templateUrl: './notiz-karte.component.html',
  styleUrls: ['./notiz-karte.component.scss'],
})
export class NotizKarteComponent {
  @ViewChild('eingabe') eingabe!: ElementRef<HTMLInputElement>;
  @Output('karteAngelegt') karteAngelegt = new EventEmitter<boolean>();
  @Input() notize: any;
  @Input() intro = '';
  @Input() maxZeichenAnzahl = 100;
  @Input('loeschenAllowed') loeschenAllowed = true;
  aktuelleZeichenAnzahl = 0;
  anpinnenErlaubt = false;

  constructor(
    private readonly authService: AuthService,
    private readonly sessionService: SessionService,
    private readonly el: ElementRef,
  ) {}

  ngOnInit() {
    this.aktualisiereKarten(
      this.el.nativeElement.parentNode.querySelectorAll("[data-status='neu']"),
    );
  }

  @HostListener('document: click', ['$event'])
  onBodyClick(event: any) {
    if (!event.target.closest('app-notiz-karte')) {
      this.schliesseKarte(false, false);
    }
  }

  oeffneKarte(event: any) {
    this.aktualisiereZeichenAnzahl();
    this.eingabe.nativeElement.value = this.notize.text;
    const notizKarte = event.target.closest('app-notiz-karte');
    if (notizKarte.dataset.dialog === 'false' && this.anereKarteBereitsGeoeffnet()) {
      document.querySelector('html')?.classList.add('dialog');
      notizKarte.dataset.dialog = 'true';
      const elementX = notizKarte.getBoundingClientRect().right;
      const elementY = notizKarte.getBoundingClientRect().y;
      const windowMiddleWidth = window.innerWidth / 2 + 300;
      const windowMiddleHeight = window.innerHeight / 2 - 300;
      const divLeft = windowMiddleWidth - elementX;
      const divTop = windowMiddleHeight - elementY;
      notizKarte.style.transform = `translate(${divLeft}px, ${divTop}px)`;
      this.eingabe.nativeElement.focus();
    }
  }

  anereKarteBereitsGeoeffnet() {
    return (
      Array.from(document.querySelectorAll('app-notiz-karte')).filter(
        (notizKarte: any) => notizKarte.dataset.dialog === 'true',
      ).length === 0
    );
  }

  schliesseKarte(textUebernehmen: boolean, textLoeschen: boolean) {
    if (!this.anpinnenErlaubt) {
      return;
    }
    const notizKarte: any = Array.from(document.querySelectorAll('app-notiz-karte')).filter(
      (nk: any) => nk.dataset.dialog === 'true',
    )[0];
    if (notizKarte === undefined) {
      return;
    }
    if (textUebernehmen && this.eingabe.nativeElement.value.trim().length > 0) {
      this.notize.text = this.eingabe.nativeElement.value;
      notizKarte.dataset.status = 'erstellt';
      const notizData = {
        id: this.notize.id,
        tokenString: this.authService.getToken(),
        notizBlockId: this.notize.notizBlockId,
        text: this.eingabe.nativeElement.value,
      };
      this.sessionService.postNotize(this.notize.notizBlockId, notizData).subscribe((data: any) => {
        this.notize.id = data.responseObject.id;
        this.karteAngelegt.emit(true);
      });
      notizKarte.style = '';
      //Auskommentieren wenn mit DB
    }
    if (textLoeschen) {
      this.notize.text = '';
      if (this.notize.id) {
        this.sessionService.updateSessionNotiz(this.notize).subscribe((data: any) => {});
      }
      notizKarte.dataset.status = 'neu';
      this.eingabe.nativeElement.value = '';
      this.karteAngelegt.emit(false);
      notizKarte.style = '';
    }

    notizKarte.dataset.dialog = 'false';
    document.querySelector('html')?.classList.remove('dialog');
    const karten = notizKarte.parentNode.querySelectorAll("[data-status='neu']");
    this.aktualisiereKarten(karten);
    notizKarte.style.transform = '';
  }
  aktualisiereZeichenAnzahl() {
    const input = this.eingabe.nativeElement;
    if (input.value.length > this.maxZeichenAnzahl) {
      input.value = input.value.substring(0, this.maxZeichenAnzahl);
    }
    this.aktuelleZeichenAnzahl = this.eingabe.nativeElement.value.length;
    this.anpinnenErlaubt = this.eingabe.nativeElement.value.trim().length > 0;
  }

  keyPressed(event: any) {
    if (this.anpinnenErlaubt && event.key === 'Enter') {
      this.schliesseKarte(true, false);
    }
  }

  aktualisiereKarten(karten: any) {
    karten.forEach((karte: any, index: number) => {
      karte.style.right = `${index * 5 + 25}px`;
      karte.style.transform = `rotate(-${index * 6}deg)`;
    });
  }
}
