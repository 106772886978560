import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-bewerten',
  templateUrl: './bewerten.component.html',
  styleUrls: ['./bewerten.component.scss'],
})
export class BewertenComponent implements OnInit {
  buttonAktiv = false;
  zuordnungen: any[] = [];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionService,
  ) {
    document.querySelector('html')?.classList.remove('dialog');
  }

  get modul() {
    return this.sessionService.currentModul;
  }

  ngOnInit() {
    document.querySelector('.intro')!.innerHTML = this.replaceAnreden(this.modul?.ordnungsIntro);
    this.sessionService.getSessionZuordnungen(this.modul?.id).subscribe((res: any) => {
      this.zuordnungen = res.responseObject;
    });
  }

  replaceAnreden(text: string) {
    const spanTemplate = "<span class='text-unterstrichen'>ANREDE</span>";
    let neuText = text.replace('dir', spanTemplate.replace('ANREDE', 'dir'));
    neuText = neuText.replace('dich', spanTemplate.replace('ANREDE', 'dich'));
    neuText = neuText.replace('deiner', spanTemplate.replace('ANREDE', 'deiner'));

    return neuText;
  }

  async kategorisieren() {
    if (this.buttonAktiv) {
      await this.router.navigateByUrl(`/modul/${this.modul.id}/kategorisieren`);
    }
  }
  async zurueck() {
    await this.router.navigateByUrl(`/modul/${this.modul.id}/checkIn`);
  }
}
