import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-notiz-block',
  templateUrl: './notiz-block.component.html',
  styleUrls: ['./notiz-block.component.scss'],
})
export class NotizBlockComponent {
  @Input('notizen') notizen: any[] = [];
  @Input('notizBlock') public notizBlock: any = {};
  @Input() position = '';
  @Input() initialAnzeigen = true;
  @Input() maxZeichenAnzahl = 100;
  @Output('minAnzahlErreicht') minAnzahlErreicht = new EventEmitter<boolean>();
  @Input('intro') intro = '';
  angelegteNotizen: any[] = [];

  initNotizen(dbNotizen: any[]) {
    for (let i = 0; i < this.notizBlock?.maxKarten; i++) {
      this.notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: this.notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenId: 123,
      });
    }
  }

  karteAngelegt(event: any, notize: any) {
    if (event) {
      if (!this.angelegteNotizen.find(x => x === notize.id)) {
        this.angelegteNotizen.push(notize.id);
      }
    } else {
      const index = this.angelegteNotizen.findIndex(x => x === notize.id);
      this.angelegteNotizen.splice(index, 1);
    }

    this.minAnzahlErreicht.emit(
      this.angelegteNotizen.length + this.notizen.filter(n => n.text.length > 0).length >=
        this.notizBlock.minKarten,
    );
  }
}
