<div class="themen-wrapper">
  <app-thema-oeffnen
    [modul]="ausgewaehltesModul"
    data-anzeigen="false"
    (schliessenEvent)="closePopup()"
  ></app-thema-oeffnen>

  <app-bearbeiten-thema *ngFor="let modul of module" [modul]="modul" (click)="openPopup(modul)">
  </app-bearbeiten-thema>

  <!-- <app-bearbeiten-thema
    (click)="
      openPopup({
        name: 'placeholder modul',
        leitfrage: 'placeholder leitfrage'
      })
    "
    [modul]="{ name: 'Placeholder Modul' }"
  ></app-bearbeiten-thema> -->
</div>
