import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-abschliessen',
  templateUrl: './abschliessen.component.html',
  styleUrls: ['./abschliessen.component.scss'],
})
export class AbschliessenComponent {
  abschliessenNotizen: any[] = [];
  fragebogen: any = null;
  constructor(
    private readonly sessionService: SessionService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly fragebogenService: FragebogenService,
  ) {
    sessionService
      .getSessionNotizen(sessionService.fragebogen.checkOut.notizBlock.id)
      .subscribe((res: any) => {
        this.abschliessenNotizen = this.initNotizen(
          sessionService.fragebogen.checkOut.notizBlock,
          res.responseObject,
        );
      });
  }

  initNotizen(notizBlock: any, dbNotizen: any) {
    const notizen = [];
    for (let i = 0; i < notizBlock.maxKarten; i++) {
      notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenString: this.authService.getToken(),
      });
    }
    return notizen;
  }

  abschied() {
    this.sessionService.abschliessen().subscribe(async (res: any) => {
      if (res.responseObject) {
        await this.router.navigateByUrl('/abschied');
      }
    });
  }

  async zurueck() {
    await this.router.navigateByUrl('/uebersicht');
  }

  blockFertig(event: any) {}
}
