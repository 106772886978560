import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth/auth.service';
import { InactivityService } from '../services/inactivity/inactivity.service';

@Component({
  selector: 'app-timeout-popup',
  templateUrl: './timeout-popup.component.html',
  styleUrls: ['./timeout-popup.component.scss'],
})
export class TimeoutPopupComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly inactivityService: InactivityService,
  ) {}
  async logout() {
    this.inactivityService.closePopup();
    this.authService.logout();
    await this.router.navigateByUrl('/anmelden');
  }
  close() {
    this.inactivityService.closePopup();
    this.inactivityService.resetInactivityTimer();
  }
}
