import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InactivityService {
  private inactivityTimer: any;
  private cachedPopup: HTMLElement | null = null;

  constructor() {}

  startInactivityTimer() {
    if (this.popup && this.popup.dataset['show'] === 'false') {
      this.stopInactivityTimer();
      this.inactivityTimer = setTimeout(
        () => {
          this.showInactivityPopup();
        },
        1000 * 60 * 40,
      ); // 40 Minuten (in Millisekunden)
    }
  }

  closePopup() {
    if (this.popup) {
      this.popup.dataset['show'] = 'false';
    }
  }

  resetInactivityTimer() {
    this.startInactivityTimer();
  }

  private stopInactivityTimer() {
    if (this.inactivityTimer) {
      clearTimeout(this.inactivityTimer);
      this.inactivityTimer = null;
    }
  }

  private showInactivityPopup() {
    if (this.popup) {
      this.popup.dataset['show'] = 'true';
    }
  }

  private get popup() {
    if (this.cachedPopup) {
      return this.cachedPopup;
    }
    this.cachedPopup = document.querySelector<HTMLElement>('app-timeout-popup');
    return this.cachedPopup;
  }
}
