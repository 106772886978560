import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';
import { LoggerFactory } from '../../../utils/logger/LoggerFactory';
import { LogLevel } from '../../../utils/logger/Logger';
import { STORAGE_KEY_DSGVO } from '../../services/storage/StorageKeys';

const console = LoggerFactory.createLogger('Route.begruessung', LogLevel.LOG);

@Component({
  selector: 'app-begruessung',
  templateUrl: './begruessung.component.html',
  styleUrls: ['./begruessung.component.scss'],
})
export class BegruessungComponent {
  dsgvoAccepted = false;
  buttonEnabled = false;

  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
  ) {
    console.log('BegruessungComponent');
    sessionService.getStatus().subscribe((res: any) => {
      if (res.responseObject) {
        this.dsgvoAccepted = res.responseObject.toLowerCase() === 'geoeffnet_und_dsgvo';
      }
    });
  }

  async los() {
    if (this.buttonEnabled || this.dsgvoAccepted) {
      this.sessionService.acceptDsgvo().subscribe(async (res: any) => {
        if (res.responseObject) {
          localStorage.setItem(STORAGE_KEY_DSGVO, 'true');
          await this.router.navigateByUrl('/');
        }
      });
    }
  }

  onInput(event: any) {
    this.buttonEnabled = event.target.checked;
  }

  openDsgvo() {
    window.open('/dsgvo', '_blank')?.focus();
  }

  get buttonText() {
    return this.dsgvoAccepted ? "Weiter geht's" : "Lost geht's";
  }
}
