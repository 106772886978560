import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SessionService } from './services/session/session.service';

@Injectable({
  providedIn: 'root',
})
export class CheckInResolver implements Resolve<boolean> {
  constructor(private readonly sessionService: SessionService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    console.log('CheckInResolver');
    return this.sessionService.checkInBearbeitet();
  }
}
