import { Component, ElementRef, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'erledigte-themen',
  templateUrl: './erledigte-themen.component.html',
  styleUrls: ['./erledigte-themen.component.scss'],
})
export class ErledigteThemenComponent {
  @Input('datenAnzeigen') datenAnzeigen = false;
  module: any[] = [];
  ausgewaehltesModul: any;

  constructor(
    private readonly sessionService: SessionService,
    private readonly elementRef: ElementRef,
  ) {
    sessionService.getSessionModuleBearbeitet(true).subscribe((data: any) => {
      this.module = data.responseObject;
    });
  }

  modulDialogOeffnen(modul: any) {
    document
      .querySelectorAll('app-thema-oeffnen[data-anzeigen=true], .popup')
      .forEach((popup: any) => {
        popup.dataset.anzeigen = false;
      });

    if (modul) {
      this.ausgewaehltesModul = modul;
      this.elementRef.nativeElement.querySelector('app-thema-oeffnen').dataset.anzeigen = true;
    }
  }

  closePopup() {
    this.elementRef.nativeElement.querySelector('app-thema-oeffnen').dataset.anzeigen = false;
  }
}
