<h4>Speichern und später wiederkommen</h4>
<p>
  Wenn du jetzt auf Unterbrechen klickst, werden deine bisherigen Antworten gespeichert und du wirst
  abgemeldet.
</p>
<p>Möchtest du die Bearbeitung des WorXplorers jetzt unterbrechen?</p>
<div style="display: flex; justify-content: space-between">
  <button class="worxplorer-button" (click)="logout()">Unterbrechen</button>
  <button class="worxplorer-button" (click)="close()">Weitermachen</button>
</div>
