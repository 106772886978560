import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth/auth.service';
import { SessionService } from '../services/session/session.service';
import { StorageService } from '../services/storage/StorageService';
import { STORAGE_KEY_DSGVO, STORAGE_KEY_USERNAME } from '../services/storage/StorageKeys';
import { LoggerFactory } from '../../utils/logger/LoggerFactory';
import { LogLevel } from '../../utils/logger/Logger';

const console = LoggerFactory.createLogger('Guard.Übersicht', LogLevel.DEBUG);

@Injectable({
  providedIn: 'root',
})
export class UebersichtGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly sessionService: SessionService,
    private readonly storageService: StorageService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    console.log('canActivate', url);
    if (this.isDsgvoDeclined()) {
      console.log('canActivate - dsgvo declined');
      return this.router.parseUrl('/begruessung');
    }

    if (this.isUsernameMissing(url)) {
      console.log('canActivate - username missing');
      return this.router.parseUrl('/name');
    }

    if (this.shouldNavigateToWeiter(url)) {
      console.log('canActivate - navigate to /weiter');
      this.navigateTo('/weiter');
      return false;
    }

    if (this.shouldHandleSessionLogic(url)) {
      console.log('Handle Session Logic');
      return this.handleSessionLogic(url);
    }

    if (this.isUsernameAvailable(url)) {
      console.log('canActivate - username available');
      this.navigateTo('/starten');
      return false;
    }

    if (this.shouldForceNavigateToWeiter(url)) {
      console.log('canActivate - force navigate to weiter');
      this.navigateTo('/weiter');
      return false;
    }

    if (this.shouldNavigateToUebersicht(url)) {
      console.log('canActivate - navigate to uebersicht');
      this.navigateTo('/uebersicht');
      return false;
    }

    return true;
  }

  private isDsgvoDeclined(): boolean {
    return this.storageService.getItem(STORAGE_KEY_DSGVO) === 'false';
  }

  private isUsernameMissing(url: string): boolean {
    return url !== '/name' && this.authService.getUsername() == null;
  }

  private shouldNavigateToWeiter(url: string): boolean {
    return (
      (url === '/uebersicht' || url === '/') &&
      this.storageService.getItem('weiterDialog') !== 'false'
    );
  }

  private shouldHandleSessionLogic(url: string): boolean {
    return ['/uebersicht', '/', '/starten', '/weiter'].includes(url);
  }

  handleSessionLogic(url: string): Observable<boolean> {
    return new Observable<boolean>(observer => {
      this.sessionService.checkInBearbeitet().subscribe((res: any) => {
        console.log('Handle Session Logic - check in bearbeitet', res);
        if (this.isSessionComplete()) {
          console.log('Handle Session Logic - session complete');
          this.navigateTo('/fertig');
          observer.next(false);
        } else if (this.shouldRedirectToUebersicht(url, res)) {
          console.log('Handle Session Logic - should redirect to uebersicht');
          this.navigateTo('/uebersicht');
          observer.next(false);
        } else if (!res.responseObject && url !== '/starten') {
          console.log('Handle Session Logic - redirect to starten');
          this.navigateTo('/starten');
          observer.next(false);
        }

        this.sessionService.getSessionModuleBearbeitet(false).subscribe((smRes: any) => {
          if (this.shouldCompleteSession(smRes)) {
            this.navigateTo('/fertig');
            this.resetSession();
          }
          observer.next(true);
        });
      });
    });
  }

  private isSessionComplete(): boolean {
    return this.storageService.getItem('fertig') === 'true';
  }

  private shouldRedirectToUebersicht(url: string, res: any): boolean {
    return (
      url !== '/uebersicht' &&
      this.storageService.getItem('weiterDialog') === 'false' &&
      res.responseObject
    );
  }

  private shouldCompleteSession(smRes: any): boolean {
    return smRes.responseObject.length === 0;
  }

  private resetSession(): void {
    this.storageService.setItem('usernameBetonen', 'false');
    this.storageService.setItem('weiterDialog', 'false');
  }

  private isUsernameAvailable(url: string): boolean {
    return url === '/name' && this.authService.getUsername() != null;
  }

  private shouldForceNavigateToWeiter(url: string): boolean {
    return url !== '/weiter' && this.storageService.getItem('weiterDialog') === 'true';
  }

  private shouldNavigateToUebersicht(url: string): boolean {
    return (
      url !== '/uebersicht' &&
      this.storageService.getItem(STORAGE_KEY_USERNAME) != null &&
      this.storageService.getItem('weiterDialog') === 'false' &&
      this.storageService.getItem('fertig') === 'false'
    );
  }

  private navigateTo(url: string): void {
    this.router.navigateByUrl(url).catch(console.error);
  }

  canActivateChild(): boolean {
    return true;
  }
}
