<app-header [isCoach]="false"></app-header>
<div class="container">
  <div class="row">
    <div class="column coach-pictures">
      <img src="/assets/img/coach_team_2.png" alt="" style="margin: 50px 0 32px 0" />
    </div>
  </div>
  <div class="row">
    <div class="column">
      <h2><span>D</span><span>u hast es geschafft!</span></h2>
    </div>
  </div>
  <div class="row" style="margin-top: 48px">
    <div class="column ml mr">
      <p style="width: 845px">
        Vielen Dank, dass du den WorXplorer bearbeitet hast. Wir hoffen, es war spannend für dich
        und hat dir Spaß gemacht. Wenn es dir gefallen hat, erzähl deinen Freunden und Bekannten
        gerne von uns. Wir haben jetzt alles, was wir von dir brauchen, um deinen Ergebnisbericht zu
        erstellen. Den bekommst du in deinem persönlichen Auswertungsgespräch. Schreib uns danach
        gerne an info&#64;worxplorer.de, wie dir das Gespräch gefallen hat und wie es für dich
        beruflich weitergegangen ist. Wir sind gespannt auf deine Erfahrungen und wünschen dir schon
        jetzt alles Gute!
      </p>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <button class="worxplorer-button" style="margin-top: 40px" (click)="buttonClicked()">
        Beenden
      </button>
    </div>
  </div>
</div>
<app-footer [anmelden]="true" [fazit]="true"></app-footer>
