<div class="ueberschrift">
  <h2 class="intro" *ngIf="ueberschriftAnzeigen">
    {{ ueberschrift }}
  </h2>
  <div class="ml mr" *ngIf="ueberschriftAnzeigen">
    <span>{{ zusatztext }}</span>
  </div>
</div>
<div class="box">
  <router-outlet></router-outlet>
</div>
