import { Injectable } from '@angular/core';
import { SessionService } from '../session/session.service';

/**
 * TODO This Class shouldnt be Used in the future
 * Instead the SessionService should be used directly
 */
@Injectable({
  providedIn: 'root',
})
export class FragebogenService {
  public checkInBearbeitet = false;

  constructor(private readonly sessionService: SessionService) {}

  async ladeCheckInBearbeitet() {
    this.sessionService.checkInBearbeitet().subscribe((res: any) => {
      this.checkInBearbeitet = res.responseObject;
    });
  }
}
