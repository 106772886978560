import { Component } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-abschied',
  templateUrl: './abschied.component.html',
  styleUrls: ['./abschied.component.scss'],
})
export class AbschiedComponent {
  constructor(private readonly authService: AuthService) {}

  buttonClicked() {
    this.authService.logout();
    document.location.href = 'http://worxplorer.de';
  }
}
