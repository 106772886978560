<div class="container">
  <div class="row">
    <div class="karte">
      <div class="color" [style.background]="colorCode"></div>
      <div class="text vc ml mr">
        <span>
          <h6>
            {{ karte?.textGross }}
          </h6>
        </span>
      </div>
      <div class="rating">
        <div class="rating-wrapper">
          <div
            class="rating-punkt"
            [ngClass]="'rating-' + i + '-star'"
            *ngFor="let item of [].constructor(5); index as i"
          ></div>
        </div>
      </div>
    </div>
    <div class="karte-slider">
      <div class="karte-kategorieren-wrapper">
        <div class="slider-content">
          <div class="slider-header">
            <ul *ngIf="kategorien">
              <li *ngFor="let item of [].constructor(5); index as i">
                {{ kategorien['prio' + (i + 1)] }}
              </li>
            </ul>
          </div>
          <div class="slider-input-container">
            <input
              #schieberegler
              id="karte-slider-rating-input"
              type="range"
              min="0"
              max="4"
              step="1"
              data-status="new"
              (input)="onInput($event)"
              (mousedown)="change($event)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="column ml">
      <button
        class="worxplorer-button"
        [ngClass]="{ inaktiv: !buttonEnabled }"
        (click)="schiebereglerSpeichern()"
      >
        Fertig
      </button>
    </div>
  </div>
</div>
