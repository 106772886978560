<app-header [modul]="'Check In'"></app-header>
<div class="container">
  <div class="row" style="padding-bottom: 24px">
    <div class="column ml-30 mr-30 p-0">
      <span class="intro light">
        {{ sessionService.fragebogen?.checkIn?.intro }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <app-single-notize
        [attr.data-anzeigen]="wichtigsteFrageAnzeigen"
        [notizBlock]="sessionService.fragebogen?.checkIn?.wichtigsteFrage"
        [maxZeichenAnzahl]="125"
        (notizAngelegt)="wichtigsteFrageFertig = true"
      ></app-single-notize>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <app-notiz-block
        [notizBlock]="sessionService.fragebogen?.checkIn?.notizBlock1"
        [position]="'links'"
        [maxZeichenAnzahl]="100"
        (minAnzahlErreicht)="blockFertig(1, $event)"
        [notizen]="notizBlock1Notizen"
      ></app-notiz-block>
    </div>
    <div class="column">
      <app-notiz-block
        [notizBlock]="sessionService.fragebogen?.checkIn?.notizBlock2"
        [position]="'rechts'"
        [maxZeichenAnzahl]="100"
        (minAnzahlErreicht)="blockFertig(2, $event)"
        [notizen]="notizBlock2Notizen"
      ></app-notiz-block>
    </div>
  </div>
  <div class="row">
    <div class="column">
      <button
        class="worxplorer-button mr-20"
        data-tooltip="Bitte gib bei jeder Frage mindestens eine Antwort, damit du fortfahren kannst."
        [ngClass]="{
          inaktiv:
            (notizBlock1Fertig &&
              notizBlock2Fertig &&
              wichtigsteFrageAnzeigen &&
              !wichtigsteFrageFertig) ||
            ((!notizBlock1Fertig || !notizBlock2Fertig) && !wichtigsteFrageAnzeigen),
        }"
        (click)="wichtigsteFrageDarstellen()"
      >
        {{ notizBlock1Fertig && notizBlock2Fertig && wichtigsteFrageAnzeigen ? 'Fertig' : 'Weiter'
        }}<svg
          *ngIf="wichtigsteFrageAnzeigen"
          style="margin-left: 10px"
          class="arrow"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M8 16L6.95 14.925L13.125 8.75H0V7.25H13.125L6.95 1.075L8 0L16 8L8 16Z"
            fill="#262231"
          ></path>
        </svg>
      </button>
    </div>
  </div>
</div>
<app-footer [uebersicht]="false"></app-footer>
