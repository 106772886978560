import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bearbeiten-thema',
  templateUrl: './bearbeiten-thema.component.html',
  styleUrls: ['./bearbeiten-thema.component.scss'],
})
export class BearbeitenThemaComponent {
  @Input('modul') modul: any = null;
}
