import { ElementRef } from '@angular/core';
import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-name',
  templateUrl: './name.component.html',
  styleUrls: ['./name.component.scss'],
})
export class NameComponent {
  @ViewChild('nameInput') nameInput!: ElementRef<HTMLInputElement>;
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly sessionService: SessionService,
  ) {}

  async worxplorerStarten() {
    this.authService.setUsername(this.nameInput.nativeElement.value);
    this.sessionService.checkInBearbeitet().subscribe((res: any) => {
      if (res.responseObject) {
        localStorage.setItem('weiterDialog', 'true');
        localStorage.setItem('usernameBetonen', 'false');
        this.router.navigateByUrl('/weiter').catch(console.error);
      } else {
        this.router.navigateByUrl('/starten').catch(console.error);
      }
    });
  }
}
