<meta name="robots" content="noindex" />
<app-header [modul]="''" [isCoach]="false"></app-header>
<div class="container">
  <div class="row">
    <div class="column">
      <h3>Datenschutzerklärung für WorXplorer</h3>
      WorXplorer bietet ein interaktives Coaching-Tool für selbstgesteuerte Entwicklung im Job an.
      Die Teilnehmer:innen des Coaching-Tools reflektieren ihren Anspruch an die eigene Arbeit und
      schätzen ein, wie wichtig ihnen verschiedene Arbeitsaspekte sind und inwiefern diese in ihrer
      aktuellen Arbeitssituation erfüllt sind.

      <div class="blue-text">
        Die Nutzung des Coaching-Tools ist nur über einen für jede(n) Teilnehmer:in individuell
        generierten Zugangslink möglich.
      </div>

      <div class="blue-text">
        Dieser Link kann entweder von dem von dir beauftragten Coach oder dem von deinem Arbeitgeber
        beauftragten Coach bei WorXplorer beantragt werden.
      </div>
      Die Datenschutzerklärung beschreibt unsere Vorgehensweise in Bezug auf die Verarbeitung
      personenbezogener Daten im Rahmen der Nutzung unserer Services.

      <hr />

      <h3>Wer ist für die Verarbeitung deiner Daten verantwortlich?</h3>
      Für die Verarbeitung der personenbezogenen Daten der Teilnehmer:innen (die Benutzer des
      Umfragetools) ist WorXplorer Verantwortlicher im Sinne von Art. 4 Nr. 7 DSGVO. Kontaktdaten
      des Verantwortlichen:
      <span class="kontakt"> WorXplorer GmbH </span>
      <span class="kontakt">Hohenstaufenring 63</span>
      <span class="kontakt">50674 Köln</span>
      <span class="kontakt">Tel.: 0178/4027214</span>
      <span class="kontakt">E-Mail: info&#64;worxplorer.de</span>

      <h3>Welche Informationen sammeln wir?</h3>
      Personenbezogene Daten sind alle Daten, die persönlich auf eine Person beziehbar sind, z.B.
      Name, Adresse, E-Mail-Adressen, Nutzerverhalten, IP-Adressen. Die personenbezogenen Daten
      werden direkt, durch die Nutzung unserer Services, z.B. die digitale Webapp erfasst. Folgende
      Informationen verarbeiten wir:
      <ul>
        <li>Zugangskennung</li>
        <li>Kontaktdaten</li>
        <li>
          Deine persönlichen Antworten innerhalb des Tools, z. B. deine Einschätzung zu vorgegebenen
          Fragen und deine Einträge in Freitextfeldern
        </li>
      </ul>
      <h3>Zwecke der Datenverarbeitung:</h3>
      <ul>
        <li>
          Erbringung von Dienstleistungen, einschließlich der Bereitstellung des Zugangs und der
          Nutzung der Webapp für die Teilnehmer:innen
        </li>
        <li>Auswertung der Antworten zur Erstellung eines Auswertungsergebnisses</li>
        <li>
          Verwaltung und Weiterentwicklung (Verbesserung der Dienste oder Funktionen) von WorXplorer
        </li>
        <li>
          Messung und Analyse des Verhaltens der Teilnehmer:innen zur Optimierung der Nutzbarkeit
        </li>
        <li>Pflege und Kontrolle von WorXplorer</li>
        <li>
          Erfüllung der vertraglichen und gesetzlichen Verpflichtungen gegenüber den
          Teilnehmer:innen
        </li>
        <li>
          Erfüllung der gesetzlichen und vertraglichen Verpflichtungen gegenüber der Partei, die den
          Link für dich beantragt hat
        </li>
      </ul>

      <h3>Rechtsgrundlage für die Verarbeitung der personenbezogenen Daten</h3>
      Rechtsgrundlage für die Datenverarbeitungsvorgänge ist deine Einwilligung (Art. 9 Abs.2
      Buchst. a i. V. m. Art. 6 Abs. 1 S. 1 Buchst. a DSGVO). D. h., dass wir die hier beschriebene
      Datenverarbeitung nur durchführen, wenn du zuvor dein Einverständnis über die gesonderte
      Einwilligungserklärung erteilt hast.
      <h3>Dauer der Datenspeicherung</h3>
      Wir verarbeiten und speichern personenbezogene Daten der betroffenen Person nur für den
      Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder aufgrund einer
      gesetzlichen Pflicht. Entfällt der Speicherungszweck oder endet die vorgeschriebene
      Speicherfrist, werden die personenbezogenen Daten routinemäßig und entsprechend den
      gesetzlichen Vorschriften gesperrt oder gelöscht. Grundsätzlich verarbeiten wir die Daten
      während der aktiven Betreuung. Anschließend werden die Daten archiviert und zur Erfüllung
      handels- und steuerrechtlicher Pflichten aufbewahrt. Nach Ablauf der gesetzlichen Fristen
      werden sie gelöscht.
      <h3>Empfänger der verarbeiteten Daten</h3>
      Teilweise bedienen wir uns zur Verarbeitung Ihrer Daten externer Dienstleister. Diese wurden
      von uns sorgfältig ausgewählt und beauftragt, sind an unsere Weisungen gebunden und werden
      regelmäßig kontrolliert. Die Auswertungsergebnisse zu deinem Zugangslink leiten wir
      ausschließlich an die Partei weiter, die für dich den Zugang beantragt hat. Diese Partei nutzt
      die Auswertungsergebnisse aufgrund der zu diesem Zeitpunkt geltenden Vereinbarungen, die
      zwischen dir und dieser Person bestehen. WorXplorer nimmt und hat keinen Einfluss auf diese
      Datenverarbeitungsvorgänge.
      <h3>Die Rechte der betroffenen Personen:</h3>
      Die Teilnehmer:innen haben folgende Rechte hinsichtlich der sie betreffenden personenbezogenen
      Daten:
      <ul>
        <li>Recht auf Auskunft</li>
        <li>Recht auf Berichtigung oder Löschung</li>
        <li>Recht auf Einschränkung der Verarbeitung</li>
        <li>Recht auf Widerspruch gegen die Verarbeitung</li>
        <li>Recht auf Widerruf der Einwilligung</li>
        <li>Recht auf Datenübertragbarkeit</li>
      </ul>

      Wir nehmen die Datenschutzrechte unserer Teilnehmer:innen ernst und sind bestrebt, ihre
      Anliegen schnell und wirksam zu lösen. Bei Bedenken, Rückfragen und Beschwerden stehen wir
      unseren Teilnehmer:innen natürlich direkt als Ansprechpartner:innen zur Verfügung. Die
      Teilnehmer:innen haben zudem das Recht, sich bei einer Datenschutz-Aufsichtsbehörde über die
      Verarbeitung der personenbezogenen Daten durch uns zu beschweren.
    </div>
  </div>
</div>
