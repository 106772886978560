import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'linke-seite',
  templateUrl: './linke-seite.component.html',
  styleUrls: ['./linke-seite.component.scss'],
})
export class LinkeSeiteComponent {
  username: any;
  usernameBetonen = true;
  datenAnzeigen: boolean;

  constructor(private readonly router: Router) {
    this.router.events.subscribe((val: any) => {
      this.username = localStorage.getItem('username');
      this.usernameBetonen = localStorage.getItem('usernameBetonen') !== 'false';
      this.datenAnzeigen = localStorage.getItem('weiterDialog') === 'false';
    });
    this.username = localStorage.getItem('username');
    this.usernameBetonen = localStorage.getItem('usernameBetonen') !== 'false';
    this.datenAnzeigen = localStorage.getItem('weiterDialog') === 'false';
  }
}
