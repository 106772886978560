import { ElementRef } from '@angular/core';
import { Component, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-schieberegler',
  templateUrl: './schieberegler.component.html',
  styleUrls: ['./schieberegler.component.scss'],
})
export class SchiebereglerComponent {
  @Input('karte') karte: any;
  @Input('zuordnung') zuordnung: any;
  @Input('kategorien') kategorien: any;
  colorCode = '#E88C03';
  @ViewChild('schieberegler') schieberegler!: ElementRef<HTMLInputElement>;
  @Output('karteBearbeitet') karteBearbeitet = new EventEmitter<number>();
  buttonEnabled = false;
  constructor(
    private readonly authService: AuthService,
    private readonly sessionService: SessionService,
  ) {}

  @HostListener('document:keydown.enter', ['$event'])
  onBodyClick(event: any) {
    const schiebereglerAktiv = document.querySelector('app-schieberegler[data-visible=true]');
    if (schiebereglerAktiv) {
      this.schiebereglerSpeichern();
    }
  }

  isNumber(val: any) {
    return typeof val === 'number';
  }

  ngOnChanges() {
    this.setColor();
    if (this.schieberegler) {
      if (this.zuordnung && this.zuordnung.schieberegler !== -1) {
        this.schieberegler.nativeElement.value = this.zuordnung.schieberegler;
        this.schieberegler.nativeElement.dataset['status'] = 'used';
        this.setRating(
          this.zuordnung.schieberegler,
          Array.from(document.querySelectorAll('.rating-punkt')),
        );
        this.buttonEnabled = true;
      } else {
        this.schieberegler.nativeElement.value = '2';
        this.schieberegler.nativeElement.dataset['status'] = 'new';
        this.setRating(-1, Array.from(document.querySelectorAll('.rating-punkt')));
        this.buttonEnabled = false;
      }
    }
  }

  setColor() {
    if (this.isNumber(this.karte?.dimension)) {
      this.sessionService
        .getDimension(this.karte.dimension)
        .subscribe((data: any) => (this.colorCode = data.responseObject!.colorCode));
    } else {
      this.colorCode = this.karte?.dimension?.colorCode;
    }
  }

  onInput(event: any) {
    this.setRating(
      this.schieberegler.nativeElement.value,
      Array.from(document.querySelectorAll('.rating-punkt')),
    );
  }

  setRating(rating: any, ratingArray: any[]) {
    ratingArray.forEach((r: any, index: number) => {
      if (index <= rating) {
        r.classList.add('aktiviert');
      } else {
        r.classList.remove('aktiviert');
      }
    });
  }

  schiebereglerSpeichern() {
    if (this.buttonEnabled) {
      const formData = {
        id: this.zuordnung.id,
        karteId: this.zuordnung.karte.id,
        tokenString: this.authService.getToken(),
        dragAndDrop: this.zuordnung.dragAndDrop,
        position: this.zuordnung.position,
        schieberegler: this.schieberegler.nativeElement.value,
      };
      this.sessionService.saveZuordnung(formData).subscribe((data: any) => {
        this.zuordnung.schieberegler = data.responseObject.schieberegler;
        this.karteBearbeitet.emit(Number(this.schieberegler.nativeElement.value));
      });
      (document.querySelector('app-schieberegler') as HTMLElement).dataset['visible'] = 'false';
      document.querySelector('html')?.classList.remove('dialog');
    }
  }
  change(event: any) {
    this.schieberegler.nativeElement.dataset['status'] = 'used';
    this.onInput(null);
    this.buttonEnabled = true;
  }
}
