<div class="color" [style.background]="colorCode"></div>
<div class="text">
  <div class="gross-text">
    <h6>
      {{ karte.textGross }}
    </h6>
  </div>
  <div class="klein-text">
    {{ karte.textKlein }}
    <div class="rating" *ngIf="isSchieberegler">
      <div
        class="rating-punkt"
        [ngClass]="'rating-' + i + '-punkt'"
        *ngFor="let item of [].constructor(5); index as i"
      ></div>
    </div>
  </div>
</div>
