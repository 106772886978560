import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-weiter',
  templateUrl: './weiter.component.html',
  styleUrls: ['./weiter.component.scss'],
})
export class WeiterComponent {
  constructor(private readonly router: Router) {}

  async buttonClicked() {
    localStorage.setItem('weiterDialog', 'false');
    await this.router.navigateByUrl('/uebersicht');
  }
}
