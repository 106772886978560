import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';
import { UebersichtComponent } from './views/uebersicht/uebersicht.component';
import { LinkeSeiteComponent } from './views/uebersicht/linke-seite/linke-seite.component';
import { ErledigteThemenComponent } from './views/uebersicht/linke-seite/erledigte-themen/erledigte-themen.component';
import { WichtigsteFrageComponent } from './views/uebersicht/linke-seite/wichtigste-frage/wichtigste-frage.component';
import { ThemaComponent } from './views/uebersicht/linke-seite/erledigte-themen/thema/thema.component';
import { RechteSeiteComponent } from './views/uebersicht/rechte-seite/rechte-seite.component';
import { FertigComponent } from './views/uebersicht/rechte-seite/fertig/fertig.component';
import { StartenComponent } from './views/uebersicht/rechte-seite/starten/starten.component';
import { NameComponent } from './views/uebersicht/rechte-seite/name/name.component';
import { WeiterComponent } from './views/uebersicht/rechte-seite/weiter/weiter.component';
import { ThemenComponent } from './views/uebersicht/rechte-seite/themen/themen.component';
import { BearbeitenThemaComponent } from './views/uebersicht/rechte-seite/themen/bearbeiten-thema/bearbeiten-thema.component';
import { ThemaOeffnenComponent } from './views/uebersicht/rechte-seite/themen/thema-oeffnen/thema-oeffnen.component';
import { CheckInComponent } from './views/check-in/check-in.component';
import { NotizBlockComponent } from './views/notiz-block/notiz-block.component';
import { NotizKarteComponent } from './views/notiz-block/notiz-karte/notiz-karte.component';
import { SingleNotizeComponent } from './views/notiz-block/single-notize/single-notize.component';
import { ModulComponent } from './views/modul/modul.component';
import { KategorisierenComponent } from './views/modul/kategorisieren/kategorisieren.component';
import { BewertenComponent } from './views/modul/bewerten/bewerten.component';
import { ModulCheckInComponent } from './views/modul/modul-check-in/modul-check-in.component';
import { HeaderComponent } from './views/layout/header/header.component';
import { FooterComponent } from './views/layout/footer/footer.component';
import { DragAndDropComponent } from './views/modul/bewerten/drag-and-drop/drag-and-drop.component';
import { MdoulKarteComponent } from './views/modul/mdoul-karte/mdoul-karte.component';
import { SchiebereglerComponent } from './views/modul/kategorisieren/schieberegler/schieberegler.component';
import { AnmeldenComponent } from './views/anmelden/anmelden.component';
import { TokenGuardGuard } from './guards/token-guard.guard';
import { AbschliessenComponent } from './views/abschliessen/abschliessen.component';
import { BegruessungComponent } from './views/begruessung/begruessung.component';
import { AbschiedComponent } from './views/abschied/abschied.component';
import { ImperssumComponent } from './views/imperssum/imperssum.component';
import { DatenschutzComponent } from './views/datenschutz/datenschutz.component';
import { TokenUsedComponent } from './views/anmelden/token-used/token-used.component';
import { SpeichernPopupComponent } from './views/uebersicht/speichern-popup/speichern-popup.component';
import { TimeoutPopupComponent } from './timeout-popup/timeout-popup.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LocalStorageService } from './services/storage/LocalStorageService';
import { StorageService } from './services/storage/StorageService';

@NgModule({
  declarations: [
    AppComponent,
    UebersichtComponent,
    LinkeSeiteComponent,
    ErledigteThemenComponent,
    WichtigsteFrageComponent,
    ThemaComponent,
    RechteSeiteComponent,
    FertigComponent,
    StartenComponent,
    NameComponent,
    WeiterComponent,
    ThemenComponent,
    BearbeitenThemaComponent,
    ThemaOeffnenComponent,
    CheckInComponent,
    NotizBlockComponent,
    NotizKarteComponent,
    SingleNotizeComponent,
    ModulComponent,
    KategorisierenComponent,
    BewertenComponent,
    ModulCheckInComponent,
    HeaderComponent,
    FooterComponent,
    DragAndDropComponent,
    MdoulKarteComponent,
    SchiebereglerComponent,
    AnmeldenComponent,
    AbschliessenComponent,
    BegruessungComponent,
    AbschiedComponent,
    ImperssumComponent,
    DatenschutzComponent,
    TokenUsedComponent,
    SpeichernPopupComponent,
    TimeoutPopupComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ModalModule.forRoot(),
  ],
  providers: [
    TokenGuardGuard,
    LocalStorageService,
    { provide: StorageService, useExisting: LocalStorageService },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
