<div class="container-wrapper vc">
  <div class="container">
    <div class="row">
      <div class="column ml mr">
        <span>
          {{ errorMessage }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="column ml mr">
        <h2><span class="text-unterstrichen">Herzlich</span> Willkommen!</h2>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <input
          #tokenInput
          (input)="onInput()"
          (keydown)="onKeydown($event)"
          type="text"
          placeholder="Bitte Token eingeben"
        />
      </div>
    </div>
    <div class="row">
      <div class="column ml mr">
        <button class="worxplorer-button" [ngClass]="{ inaktiv: !buttonEnabled }" (click)="login()">
          Login
        </button>
      </div>
    </div>
  </div>
</div>
<app-footer [anmelden]="true"></app-footer>
