import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thema-oeffnen',
  templateUrl: './thema-oeffnen.component.html',
  styleUrls: ['./thema-oeffnen.component.scss'],
})
export class ThemaOeffnenComponent {
  @Input('modul') modul: any;
  @Input('anzeigen') public anzeigen = false;
  @Output() schliessenEvent = new EventEmitter<boolean>();

  constructor(private readonly router: Router) {}

  popupSchliessen() {
    this.schliessenEvent.emit(true);
  }

  async bearbeiten() {
    await this.router.navigateByUrl(`/modul/${this.modul.id}/checkIn`);
  }
}
