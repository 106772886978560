import { Component, Input } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-mdoul-karte',
  templateUrl: './mdoul-karte.component.html',
  styleUrls: ['./mdoul-karte.component.scss'],
})
export class MdoulKarteComponent {
  @Input() karte: any;
  @Input('isZugeordnet') isZugeordnet = false;
  colorCode = '#E88C03';
  @Input('karteZurodnung') zuordnungen: any[] = [];
  @Input('isSchieberegler') isSchieberegler = false;

  constructor(private readonly sessionService: SessionService) {}

  isNumber(val: any) {
    return typeof val === 'number';
  }

  ngOnChanges() {
    this.setColor();
    // this.setRating();
  }

  setRating() {
    if (this.zuordnungen.length > 0 && this.karte != null) {
      const zuordnung = this.zuordnungen.filter(zu => zu.karte.id === this.karte.id)[0];
      const docKarte = document.getElementById(this.karte.kennung);
      if (docKarte == null) {
        return;
      }
      const ratingArray = Array.from(docKarte.querySelectorAll('.rating-punkt'));
      ratingArray.forEach((r: any, index: number) => {
        if (index <= zuordnung.schieberegler) {
          r.classList.add('aktiviert');
        } else {
          r.classList.remove('aktiviert');
        }
      });
    }
  }

  setColor() {
    if (this.isNumber(this.karte.dimension)) {
      this.sessionService.getDimension(this.karte.dimension).subscribe((data: any) => {
        this.colorCode = data.responseObject!.colorCode;
      });
    } else {
      this.colorCode = this.karte.dimension.colorCode;
    }
  }
}
