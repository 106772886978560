<div class="container">
  <div class="row">
    <div class="column ml-30 mr-30 p-0">
      <span class="intro"> </span>
    </div>
  </div>
  <div class="row">
    <div class="column ml-30 p-0">Du kannst in jede Spalte bis zu drei Karten einordnen.</div>
  </div>
  <div class="row">
    <div class="column">
      <app-drag-and-drop
        [zuordnungen]="zuordnungen"
        (alleKartenZugeordnet)="buttonAktiv = $event"
      ></app-drag-and-drop>
    </div>
  </div>
  <div class="row" style="margin-top: 16px">
    <div class="column mr">
      <button class="worxplorer-button ml-20" (click)="zurueck()">
        <span>
          <svg
            width="18"
            height="12"
            viewBox="0 0 18 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.975 11.95L0 5.975L5.975 0L7.05 1.075L2.9 5.225H18V6.725H2.9L7.05 10.875L5.975 11.95Z"
              fill="#262231"
            ></path>
          </svg>
          Zurück
        </span>
      </button>
    </div>
    <div class="column ml">
      <button
        class="worxplorer-button mr-20"
        [ngClass]="{ inaktiv: !buttonAktiv }"
        (click)="kategorisieren()"
        data-tooltip="Bitte sortiere alle Karten ein, damit du fortfahren kannst."
      >
        <span>
          Weiter Geht's
          <svg
            class="arrow"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8 16L6.95 14.925L13.125 8.75H0V7.25H13.125L6.95 1.075L8 0L16 8L8 16Z"
              fill="#262231"
            ></path>
          </svg>
        </span>
      </button>
    </div>
  </div>
</div>
