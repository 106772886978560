<div class="content">
  <div class="container">
    <div class="row">
      <div class="column" style="display: flex">
        <span class="leitfrage" [innerHTML]="replaceAnreden(notizBlock?.intro)"> </span>

        <span>
          {{ notizBlock?.denkanstoss }}
        </span>
      </div>
      <div class="column" style="height: 220px; position: relative">
        <app-notiz-karte
          *ngFor="let notize of notizen"
          [attr.data-status]="notize?.text && notize?.text.trim() ? 'erstellt' : 'neu'"
          [attr.data-dialog]="false"
          [notize]="notize"
          [intro]="'Welche Frage möchtest du für die Bearbeitung im Kopf behalten?'"
          [maxZeichenAnzahl]="maxZeichenAnzahl"
          [loeschenAllowed]="false"
          (karteAngelegt)="emitEvent()"
        ></app-notiz-karte>
      </div>
    </div>
  </div>
</div>
