import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-drag-and-drop',
  templateUrl: './drag-and-drop.component.html',
  styleUrls: ['./drag-and-drop.component.scss'],
})
export class DragAndDropComponent {
  @Input('zuordnungen') zuordnungen: any[] = [];
  @Output('alleKartenZugeordnet') alleKartenZugeordnet = new EventEmitter<boolean>();
  textAnzeigen = false;

  constructor(
    private readonly sessionService: SessionService,
    private readonly authService: AuthService,
  ) {}

  get modul() {
    return this.sessionService.currentModul;
  }

  ngOnChanges() {
    if (this.zuordnungen.length !== 0) {
      this.zuordnungenAufKartenAnwenden();
    }
    this.stapelAktualisieren();
  }

  get modulKarten() {
    return this.modul.karten.sort(
      (k1: any, k2: any) => this.kartenNummer(k2) - this.kartenNummer(k1),
    );
  }

  private kartenNummer(karte: any) {
    return parseInt(karte.kennung.slice(-1));
  }

  zuordnungenAufKartenAnwenden() {
    this.zuordnungen.forEach((kz: any) => {
      const karte = document.getElementById(kz.karte.kennung);
      const spalte = document.querySelector(`[data-zuordnung="${kz.dragAndDrop}"]`);
      const karteAblage = kz.position ? spalte?.querySelectorAll('.ablage')[kz.position - 1] : null;
      this.moveKarte(karte, spalte, karteAblage);
    });
  }

  openKarte(karte: any) {
    const karteEl = document.getElementById(karte.kennung);
    if (
      karteEl == null ||
      karteEl.dataset['zugeordnet'] === 'false' ||
      document.querySelector("[data-dialog='true']")
    ) {
      return;
    }
    document.querySelector('html')?.classList.add('dialog');
    karteEl.dataset['dialog'] = 'true';
    const elementX = karteEl.getBoundingClientRect().left;
    const elementY = karteEl.getBoundingClientRect().y;
    const windowMiddleWidth = window.innerWidth / 2 - 114;
    const windowMiddleHeight = window.innerHeight / 2 - 114;
    const divLeft = windowMiddleWidth - elementX;
    const divTop = windowMiddleHeight - elementY;
    karteEl.style.transform = `translate(${divLeft}px, ${divTop}px)`;
  }

  drag(event: any) {
    event.dataTransfer.setData('karteId', event.target.id);
  }

  isAblageFrei(ablage: any) {
    return ablage.children.length === 0;
  }

  moveKarte(karte: any, spalte: any, targetAblage: any) {
    const naechsteFreieAblage = Array.from(spalte.querySelectorAll('.ablage')).filter(a =>
      this.isAblageFrei(a),
    )[0];

    const karteNichtInSpalte =
      Array.from(spalte.querySelectorAll('app-mdoul-karte')).filter((k: any) => k.id === karte?.id)
        .length === 0;

    if (targetAblage && this.isAblageFrei(targetAblage)) {
      targetAblage.appendChild(karte);
      karte.dataset.zugeordnet = 'true';
      karte!.style.transform = '';
    } else if (
      naechsteFreieAblage !== null &&
      naechsteFreieAblage instanceof HTMLDivElement &&
      karteNichtInSpalte
    ) {
      naechsteFreieAblage.appendChild(karte);
      karte.dataset.zugeordnet = 'true';
      karte!.style.transform = '';
    }
    this.updateView();
  }

  drop(event: any) {
    const karte = document.getElementById(event.dataTransfer.getData('karteId'));
    const ablage = event.target.closest('.ablage');
    const spalteValue = (ablage.closest('.spalte') || HTMLElement).dataset.zuordnung;
    const position = ablage.dataset.position;
    if (karte === null) {
      this.entferneDragOver();
      return;
    }
    this.moveKarte(karte, event.target.closest('.spalte'), event.target.closest('.ablage'));
    this.speicherZuordnung(karte, spalteValue, position);
    this.updateView();
  }

  entferneDragOver() {
    Array.from(document.querySelectorAll('.dragOver')).forEach(ablage =>
      ablage.classList.remove('dragOver'),
    );
  }

  updateView() {
    this.entferneDragOver();
    this.textAnzeigen =
      document.querySelectorAll<HTMLElement>("app-modul-karte[data-zugeordnet='false']").length ===
      0;
    this.alleKartenZugeordnet.emit(this.textAnzeigen);
    this.stapelAktualisieren();
  }

  stapelAktualisieren() {
    const kartenAblage = document.getElementById('karten-ablage');
    const karten = kartenAblage?.querySelectorAll('app-mdoul-karte')!;
    for (let i = 0; i < karten.length - 1; i++) {
      const karte = karten[i] as HTMLElement;
      karte.style.transform = 'rotate(7deg) translate(-53%, -58%)';
    }
    console.log(karten);

    if (karten.length > 0) {
      const ersteKarte = karten[karten.length - 1] as HTMLElement;
      ersteKarte.style.transform = '';
    }
  }

  speicherZuordnung(karte: any, spalte: number, position: any) {
    const zuordnung = this.zuordnungen.find((kz: any) => kz?.karte?.kennung === karte?.id);
    const karteId = this.modul?.karten.find((k: any) => k.kennung === karte?.id).id;
    const postData = {
      id: zuordnung ? zuordnung.id : null,
      karteId: karteId,
      tokenString: this.authService.getToken(),
      dragAndDrop: spalte,
      schieberegler: zuordnung ? zuordnung.schieberegler : -1,
      position: position,
    };

    this.sessionService.saveZuordnung(postData).subscribe((data: any) => {
      if (!zuordnung) {
        this.zuordnungen.push(data.responseObject);
      }
    });
  }

  dragOver(event: any) {
    event.preventDefault();
  }

  dragEnter(event: any) {
    if (event.target.classList.contains('ablage')) {
      event.target.classList.add('dragOver');
    }
  }

  dragLeave(event: any) {
    if (event.target.classList.contains('ablage')) {
      event.target.classList.remove('dragOver');
    }
  }
}
