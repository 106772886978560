import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-speichern-popup',
  templateUrl: './speichern-popup.component.html',
  styleUrls: ['./speichern-popup.component.scss'],
})
export class SpeichernPopupComponent {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
  ) {}

  async logout() {
    this.authService.logout();
    await this.router.navigateByUrl('/anmelden');
  }
  close() {
    const popup = document.querySelector<HTMLElement>('app-speichern-popup');
    if (popup) {
      popup.dataset['anzeigen'] = 'false';
    }
  }
}
