import { Component, Input } from '@angular/core';

@Component({
  selector: 'erledigtes-thema',
  templateUrl: './thema.component.html',
  styleUrls: ['./thema.component.scss'],
})
export class ThemaComponent {
  @Input('modul') modul?: any;
}
