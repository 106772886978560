<div class="content" [ngClass]="{ invisible: !initialAnzeigen }">
  <div class="container">
    <div class="row">
      <div class="column">
        <span class="leitfrage">
          {{ notizBlock?.intro }}
        </span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span
          class="denkanstoss"
          [hidden]="!notizBlock?.denkanstoss"
          [attr.data-denkanstoss]="notizBlock?.denkanstoss"
        >
          Denkanstöße gefällig?
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.1 15.825C10.3667 15.825 10.5917 15.7333 10.775 15.55C10.9583 15.3667 11.05 15.1417 11.05 14.875C11.05 14.6083 10.9583 14.3833 10.775 14.2C10.5917 14.0167 10.3667 13.925 10.1 13.925C9.83333 13.925 9.60833 14.0167 9.425 14.2C9.24167 14.3833 9.15 14.6083 9.15 14.875C9.15 15.1417 9.24167 15.3667 9.425 15.55C9.60833 15.7333 9.83333 15.825 10.1 15.825ZM9.225 12.175H10.7C10.7 11.7417 10.7542 11.3458 10.8625 10.9875C10.9708 10.6292 11.3083 10.2167 11.875 9.75C12.3917 9.31667 12.7583 8.89167 12.975 8.475C13.1917 8.05833 13.3 7.6 13.3 7.1C13.3 6.21667 13.0125 5.50833 12.4375 4.975C11.8625 4.44167 11.1 4.175 10.15 4.175C9.33333 4.175 8.6125 4.37917 7.9875 4.7875C7.3625 5.19583 6.90833 5.75833 6.625 6.475L7.95 6.975C8.13333 6.50833 8.40833 6.14583 8.775 5.8875C9.14167 5.62917 9.575 5.5 10.075 5.5C10.6417 5.5 11.1 5.65417 11.45 5.9625C11.8 6.27083 11.975 6.66667 11.975 7.15C11.975 7.51667 11.8667 7.8625 11.65 8.1875C11.4333 8.5125 11.1167 8.85 10.7 9.2C10.2 9.63333 9.82917 10.0625 9.5875 10.4875C9.34583 10.9125 9.225 11.475 9.225 12.175ZM10 20C8.63333 20 7.34167 19.7375 6.125 19.2125C4.90833 18.6875 3.84583 17.9708 2.9375 17.0625C2.02917 16.1542 1.3125 15.0917 0.7875 13.875C0.2625 12.6583 0 11.3667 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02917 3.825 2.9375 2.925C3.84583 2.025 4.90833 1.3125 6.125 0.7875C7.34167 0.2625 8.63333 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3667 19.7375 12.6583 19.2125 13.875C18.6875 15.0917 17.975 16.1542 17.075 17.0625C16.175 17.9708 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20ZM10 18.5C12.3667 18.5 14.375 17.6708 16.025 16.0125C17.675 14.3542 18.5 12.35 18.5 10C18.5 7.63333 17.675 5.625 16.025 3.975C14.375 2.325 12.3667 1.5 10 1.5C7.65 1.5 5.64583 2.325 3.9875 3.975C2.32917 5.625 1.5 7.63333 1.5 10C1.5 12.35 2.32917 14.3542 3.9875 16.0125C5.64583 17.6708 7.65 18.5 10 18.5Z"
              fill="#262231"
            ></path>
          </svg>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <app-notiz-karte
          *ngFor="let notize of notizen"
          [attr.data-status]="notize?.text && notize?.text.trim() ? 'erstellt' : 'neu'"
          [attr.data-dialog]="false"
          [notize]="notize"
          [intro]="intro ? intro : notizBlock?.intro"
          [maxZeichenAnzahl]="maxZeichenAnzahl"
          (karteAngelegt)="karteAngelegt($event, notize)"
        ></app-notiz-karte>
      </div>
    </div>
  </div>
</div>
