import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { LoggerFactory } from '../../../utils/logger/LoggerFactory';
import { LogLevel } from '../../../utils/logger/Logger';
import { STORAGE_KEY_FRAGEBOGEN } from '../storage/StorageKeys';
import { StorageService } from '../storage/StorageService';

const console = LoggerFactory.createLogger('Service.Session', LogLevel.LOG);

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  public notizen: any[] = [];
  private _fragebogen: any = null;
  private _checkInBearbeitet = false;
  private _currentModul: any;

  constructor(
    private readonly http: HttpClient,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly storageService: StorageService,
  ) {}

  async anmelden(token: string) {
    try {
      const data: any = await lastValueFrom(
        this.http.get(`/api/fragebogenSession/anmelden?token=${token}`),
      );
      console.log('Anmelden Response', data);
      if (!this.authService.login(data.responseObject.token)) {
        throw new Error('Anmeldung nicht möglich!');
      }
      this.fragebogen = data.responseObject.token.fragebogen;
      this._checkInBearbeitet = data.responseObject.checkInBearbeitet;
      await this.router.navigateByUrl('/begruessung');
    } catch (err: any) {
      if (err.error?.tokenAlreadyUsed) {
        await this.router.navigateByUrl('/tokenUsed');
      } else {
        throw new Error(err.error?.message || 'Ein Fehler ist aufgetreten');
      }
    }
  }

  abschliessen() {
    return this.http.put(
      `/api/fragebogenSession/tokenBearbeitet?token=${this.authService.getToken()}`,
      {},
    );
  }

  acceptDsgvo() {
    return this.http.put(`/api/fragebogenSession/dsgvo?token=${this.authService.getToken()}`, {});
  }

  getStatus() {
    return this.http.get(`/api/fragebogenSession/status?token=${this.authService.getToken()}`);
  }

  getSessionModuleBearbeitet(bearbeitet: boolean) {
    return this.http.get(
      `/api/fragebogenSession/module/filter?bearbeitet=${bearbeitet}&token=${this.authService.getToken()}`,
    );
  }

  getSessionNotizen(notizBlock: number) {
    return this.http.get(
      `/api/fragebogenSession/notizen?notizBlock=${notizBlock}&token=${this.authService.getToken()}`,
    );
  }

  getSessionWichtigsteFrage() {
    return this.http.get(
      `/api/fragebogenSession/notizen/wichtigsteFrage?token=${this.authService.getToken()}`,
    );
  }

  updateSessionNotiz(notiz: any) {
    return this.http.put(
      `/api/fragebogenSession/notizen/${notiz.id}?token=${this.authService.getToken()}`,
      notiz,
    );
  }

  getSessionZuordnungen(modulId: any) {
    return this.http.get(
      `/api/fragebogenSession/zuordnungen/${modulId}?token=${this.authService.getToken()}`,
    );
  }

  saveZuordnung(zuordnung: any) {
    return this.http.post(
      `/api/fragebogenSession/zuordnungen?token=${this.authService.getToken()}`,
      zuordnung,
    );
  }

  //TEST
  getDimension(dimensionId: any) {
    return this.http.get(
      `/api/fragebogenSession/dimensionen/${dimensionId}?token=${this.authService.getToken()}`,
    );
  }

  postNotize(notizBlockId: any, notiz: any) {
    return this.http.post(
      `/api/fragebogenSession/notizBloecke/${notizBlockId}/notizen?token=${this.authService.getToken()}`,
      notiz,
    );
  }

  checkInBearbeitet() {
    return this.http.get(
      `/api/fragebogenSession/checkInBearbeitet?token=${this.authService.getToken()}`,
    );
  }

  set fragebogen(fragebogen: any) {
    this.storageService.setItem(STORAGE_KEY_FRAGEBOGEN, JSON.stringify(fragebogen));
    this._fragebogen = fragebogen;
  }

  get fragebogen() {
    if (this._fragebogen === null) {
      const storageFragebogen = this.storageService.getItem(STORAGE_KEY_FRAGEBOGEN);
      if (storageFragebogen) {
        const parsedFragebogen = JSON.parse(storageFragebogen);
        this._fragebogen = parsedFragebogen;
      } else {
        throw new Error('Fragebogen steht nicht bereit');
      }
    }
    return this._fragebogen;
  }

  get currentModul() {
    return this._currentModul;
  }

  set currentModul(modul: any) {
    this._currentModul = modul;
  }

  get vcheckInBearbeitet() {
    return this._checkInBearbeitet;
  }
}
