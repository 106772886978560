import { OnInit } from '@angular/core';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-modul-check-in',
  templateUrl: './modul-check-in.component.html',
  styleUrls: ['./modul-check-in.component.scss'],
})
export class ModulCheckInComponent implements OnInit {
  buttonEnabled = false;
  block1Fertig = false;
  block1Notizen: any[] = [];
  block2Fertig = false;
  block2Notizen: any[] = [];
  constructor(
    private readonly router: Router,
    private readonly sessionService: SessionService,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
  ) {}

  get modul() {
    return this.sessionService.currentModul;
  }

  ngOnInit(): void {
    this.sessionService
      .getSessionNotizen(this.modul?.modulCheckIn.notizBlock1.id)
      .subscribe((notizen: any) => {
        this.block1Notizen = this.initNotizen(
          this.modul?.modulCheckIn.notizBlock1,
          notizen.responseObject,
        );
        this.block1Fertig = this.isBlockFertig(
          this.block1Notizen,
          this.modul?.modulCheckIn.notizBlock1,
        );
        this.updateButtonEnabled();
      });
    this.sessionService
      .getSessionNotizen(this.modul?.modulCheckIn.notizBlock2.id)
      .subscribe((notizen: any) => {
        this.block2Notizen = this.initNotizen(
          this.modul?.modulCheckIn.notizBlock2,
          notizen.responseObject,
        );
        this.block2Fertig = this.isBlockFertig(
          this.block2Notizen,
          this.modul?.modulCheckIn.notizBlock2,
        );
        this.updateButtonEnabled();
      });
  }

  isBlockFertig(notizen: any[], notizBlock: any) {
    return notizen.filter((notiz: any) => notiz.text?.length > 0).length >= notizBlock.minKarten;
  }

  initNotizen(notizBlock: any, dbNotizen: any) {
    const notizen = [];
    for (let i = 0; i < notizBlock.maxKarten; i++) {
      notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenString: this.authService.getToken(),
      });
    }
    return notizen;
  }

  blockFertig(block: number, fertig: boolean) {
    if (block === 1) {
      this.block1Fertig = fertig;
    } else {
      this.block2Fertig = fertig;
    }
    this.updateButtonEnabled();
  }

  updateButtonEnabled() {
    this.buttonEnabled = this.modulCheckInFertig();
  }

  modulCheckInFertig() {
    return this.block1Fertig && this.block2Fertig;
  }

  async bewerten() {
    if (this.modulCheckInFertig()) {
      await this.router.navigateByUrl(`/modul/${this.modul?.id}/bewerten`);
    }
  }
}
