import { Component, ElementRef, ViewChild } from '@angular/core';
import { SessionService } from 'src/app/services/session/session.service';
import { LoggerFactory } from '../../../utils/logger/LoggerFactory';
import { LogLevel } from '../../../utils/logger/Logger';

const console = LoggerFactory.createLogger('Route.anmelden', LogLevel.LOG);

@Component({
  selector: 'app-anmelden',
  templateUrl: './anmelden.component.html',
  styleUrls: ['./anmelden.component.scss'],
})
export class AnmeldenComponent {
  buttonEnabled = false;
  @ViewChild('tokenInput') tokenInput!: ElementRef<HTMLInputElement>;
  errorMessage = '';

  constructor(private readonly sessionService: SessionService) {}

  async login() {
    if (this.buttonEnabled) {
      try {
        await this.sessionService.anmelden(this.tokenInput.nativeElement.value);
      } catch (e: any) {
        this.errorMessage = e.toString();
        setTimeout(() => (this.errorMessage = ''), 2500);
      }
    }
  }

  onInput() {
    this.buttonEnabled = this.tokenInput.nativeElement.value.length > 0;
  }

  async onKeydown(event: KeyboardEvent) {
    if (event.defaultPrevented) {
      return;
    }
    if (event.key === 'Enter') {
      await this.login();
    }
  }

  protected readonly event = event;
}
