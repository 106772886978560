import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth/auth.service';
import { FragebogenService } from 'src/app/services/fragebogen/fragebogen.service';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-check-in',
  templateUrl: './check-in.component.html',
  styleUrls: ['./check-in.component.scss'],
})
export class CheckInComponent {
  wichtigsteFrageAnzeigen = false;
  notizBlock1Fertig = false;
  notizBlock2Fertig = false;
  wichtigsteFrageFertig = false;
  notizBlock1Notizen: any[] = [];
  notizBlock2Notizen: any[] = [];
  wichtigsteFrageNotizen: any[] = [];

  constructor(
    protected readonly sessionService: SessionService,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly fragebogenService: FragebogenService,
  ) {
    sessionService
      .getSessionNotizen(sessionService.fragebogen.checkIn.notizBlock1.id)
      .subscribe((res: any) => {
        console.log('CHECKIN', res);

        this.notizBlock1Notizen = this.initNotizen(
          sessionService.fragebogen.checkIn.notizBlock1,
          res.responseObject,
        );
        this.notizBlock1Fertig =
          this.notizBlock1Notizen.filter((notiz: any) => notiz.text?.length > 0).length >=
          sessionService.fragebogen.checkIn.notizBlock1.minKarten;
        this.wichtigsteFrageDarstellen();
      });

    sessionService
      .getSessionNotizen(sessionService.fragebogen.checkIn.notizBlock2.id)
      .subscribe((res: any) => {
        this.notizBlock2Notizen = this.initNotizen(
          sessionService.fragebogen.checkIn.notizBlock2,
          res.responseObject,
        );
        this.notizBlock2Fertig =
          this.notizBlock2Notizen.filter((notiz: any) => notiz.text?.length > 0).length >=
          sessionService.fragebogen.checkIn.notizBlock2.minKarten;
        this.wichtigsteFrageDarstellen();
      });

    sessionService
      .getSessionNotizen(sessionService.fragebogen.checkIn.wichtigsteFrage.id)
      .subscribe((res: any) => {
        this.wichtigsteFrageNotizen = this.initNotizen(
          sessionService.fragebogen.checkIn.wichtigsteFrage,
          res.responseObject,
        );
        this.wichtigsteFrageFertig =
          this.wichtigsteFrageNotizen.filter((notiz: any) => notiz.text?.length > 0).length >=
          sessionService.fragebogen.checkIn.wichtigsteFrage.minKarten;
        this.wichtigsteFrageDarstellen();
      });
  }

  initNotizen(notizBlock: any, dbNotizen: any) {
    const notizen = [];
    for (let i = 0; i < notizBlock.maxKarten; i++) {
      notizen.push({
        id: dbNotizen[i]?.id,
        notizBlockId: notizBlock.id,
        text: dbNotizen[i] ? dbNotizen[i].text : '',
        tokenString: this.authService.getToken(),
      });
    }
    return notizen;
  }

  wichtigsteFrageDarstellen() {
    const checkInAbschliessenErlaubt = this.wichtigsteFrageAnzeigen;
    this.wichtigsteFrageAnzeigen = this.notizBlock1Fertig && this.notizBlock2Fertig;
    if (
      this.wichtigsteFrageFertig &&
      this.notizBlock1Fertig &&
      this.notizBlock2Fertig &&
      checkInAbschliessenErlaubt
    ) {
      localStorage.setItem('weiterDialog', 'true');
      localStorage.setItem('usernameBetonen', 'false');
      this.fragebogenService
        .ladeCheckInBearbeitet()
        .then(() => this.router.navigateByUrl('/weiter'))
        .catch(console.error);
    }
  }

  blockFertig(block: number, fertig: boolean) {
    if (block === 1) {
      this.notizBlock1Fertig = fertig;
    } else {
      this.notizBlock2Fertig = fertig;
    }
    if (this.wichtigsteFrageAnzeigen) {
      this.wichtigsteFrageAnzeigen = this.notizBlock1Fertig && this.notizBlock2Fertig;
    }
  }
}
