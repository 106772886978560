import { Injectable } from '@angular/core';
import { StorageService } from '../storage/StorageService';
import { STORAGE_KEY_DSGVO, STORAGE_KEY_TOKEN, STORAGE_KEY_USERNAME } from '../storage/StorageKeys';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private readonly storageService: StorageService) {}

  logout() {
    this.storageService.clear();
  }

  login(token: { status: string; tokenString: string }) {
    if (token == null) {
      return false;
    }
    this.storageService.setItem(
      STORAGE_KEY_DSGVO,
      (token.status.toLowerCase() === 'geoeffnet_und_dsgvo').toString(),
    );
    this.storageService.setItem(STORAGE_KEY_TOKEN, token.tokenString);
    return true;
  }

  //TODO this method needs to check the current cookie session
  get isLoggedIn() {
    return this.storageService.getItem(STORAGE_KEY_TOKEN) != null;
  }

  getToken() {
    return this.storageService.getItem(STORAGE_KEY_TOKEN);
  }

  getUsername() {
    return this.storageService.getItem(STORAGE_KEY_USERNAME);
  }

  setUsername(name: string) {
    this.storageService.setItem(STORAGE_KEY_USERNAME, name);
  }
}
