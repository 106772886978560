import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'rechte-seite',
  templateUrl: './rechte-seite.component.html',
  styleUrls: ['./rechte-seite.component.scss'],
})
export class RechteSeiteComponent {
  bearbeiteteModule: any[] = [];
  fragebogenModule: any[] = [];
  ueberschriftAnzeigen = false;
  @Input('ueberschrift') ueberschrift = '';
  zusatztext = '';

  constructor(
    private readonly sessionService: SessionService,
    private readonly router: Router,
  ) {
    this.router.events.subscribe((val: any) => {
      this.getBearbeitetModule();
    });
    this.fragebogenModule = sessionService.fragebogen.module;
  }

  getBearbeitetModule() {
    this.sessionService.getSessionModuleBearbeitet(true).subscribe((res: any) => {
      this.bearbeiteteModule = res.responseObject;
      this.ermittelUeberschrift();
    });
  }

  ermittelUeberschrift() {
    this.ueberschriftAnzeigen = localStorage.getItem('weiterDialog') === 'false';
    if (localStorage.getItem('username') == null) {
      this.ueberschrift = '';
      this.zusatztext = '';
    } else if (this.bearbeiteteModule.length === 0) {
      this.ueberschrift = 'Mit welchem Thema möchtest du starten?';
      this.zusatztext =
        'Klick das Thema an, das du als erstes bearbeiten möchtest und erfahre mehr darüber';
      localStorage.setItem('fertig', 'false');
    } else if (this.bearbeiteteModule.length === this.fragebogenModule.length) {
      this.ueberschrift = 'Du hast alle Themen bearbeitet!';
      this.zusatztext = '';
      localStorage.setItem('fertig', 'true');
    } else {
      this.zusatztext = '';
      localStorage.setItem('fertig', 'false');
      this.ueberschrift = 'Mit welchem Thema möchtest du weitermachen?';
    }
  }
}
