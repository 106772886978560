import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fertig',
  templateUrl: './fertig.component.html',
  styleUrls: ['./fertig.component.scss'],
})
export class FertigComponent {
  constructor(private readonly router: Router) {}

  async abschliessen() {
    await this.router.navigateByUrl('/abschliessen');
  }
}
