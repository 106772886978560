import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';
import { LoggerFactory } from '../../utils/logger/LoggerFactory';
import { LogLevel } from '../../utils/logger/Logger';
import { AuthService } from '../services/auth/auth.service';

const console = LoggerFactory.createLogger('Guard.Token', LogLevel.LOG);

@Injectable({
  providedIn: 'root',
})
export class TokenGuardGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('TokenGuardGuard.canActivate');
    if (this.authService.isLoggedIn) {
      return true;
    }
    this.router.navigateByUrl('/anmelden').catch(console.error);
    return false;
  }
}
