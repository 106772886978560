<app-header [modul]="''"></app-header>
<app-speichern-popup class="popup" data-anzeigen="false"></app-speichern-popup>
<div class="container">
  <div class="row">
    <div class="column column-33">
      <linke-seite></linke-seite>
    </div>
    <div class="column">
      <rechte-seite></rechte-seite>
    </div>
  </div>
</div>
<app-footer [uebersicht]="true"></app-footer>
