import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  @Input('uebersicht') uebersicht = true;
  @Input('modul') modul: any;
  @Input('anzahlModulAufgaben') anzahlModulAufgabe = 0;
  @Input('aktuelleModulAufgabe') aktuelleModulAufgabe = 0;
  @Input('anmelden') anmelden = false;
  @Input('fazit') fazit = false;
  @Input('begruessung') begruessung = false;

  constructor(private readonly router: Router) {}

  impressum() {
    window.open('/impressum', '_blank')?.focus();
  }

  async uebersichtAnzeigen() {
    await this.router.navigateByUrl('/uebersicht');
  }

  async fazitAnzeigen() {
    await this.router.navigateByUrl('/abschliessen');
  }

  logout() {
    const popup = document.querySelector<HTMLElement>('app-speichern-popup');
    if (popup) {
      document.querySelectorAll('app-thema-oeffnen[data-anzeigen=true]').forEach((pop: any) => {
        pop.dataset.anzeigen = false;
      });
      popup.dataset['anzeigen'] = 'true';
    }
  }
}
