<div class="container">
  <div class="impressum">
    <div class="impressum-header">Impressum</div>
    <div class="impressum-text">
        <span class="impressum-header">

      Angaben gemäß § 5 TMG
        </span>

      <span>WorXplorer GmbH</span>
      <span>Hohenstaufenring 63</span>
      <span>50674 Köln</span>
      <span class="impressum-header">Vertreten durch:</span>
      <span>Anne Janser</span>
      <span>Daniel Putz</span>
      <span class="impressum-header">Kontakt:</span>
      <span>Telefon: +49 151 44952196</span>
      <span>E-Mail: info&#64;worxplorer.de</span>
      <span class="impressum-header">Registereintrag:</span>
      <span>Eintragung im Handelsregister.</span>
      <span>Registergericht: Köln</span>
      <span>Registernummer: HRB 113334</span>
      <span class="impressum-header">Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:</span>
      <span>Anne Janser und Daniel Putz</span>
      <span>Hohenstaufenring 63</span>
      <span>50674 Köln</span>
      <span class="impressum-header">Haftungsausschluss:</span>
      <span class="impressum-header">Haftung für Inhalte</span>
      <span>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die Richtigkeit,
        Vollständigkeit und Aktualität der Inhalte können wir jedoch keine Gewähr übernehmen. Als
        Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den
        allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
        jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen
        oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst
        ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden
        von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </span>
      <span class="impressum-header">Haftung für Links</span>
      <span>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr übernehmen.
        Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der
        Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf
        mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung
        nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne
        konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von
        Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </span>
      <span class="impressum-header">Urheberrecht</span>
      <span>
        Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten unterliegen dem
        deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, Verbreitung und jede Art der
        Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung
        des jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite sind nur für den
        privaten, nicht kommerziellen Gebrauch gestattet. Soweit die Inhalte auf dieser Seite nicht
        vom Betreiber erstellt wurden, werden die Urheberrechte Dritter beachtet. Insbesondere
        werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie trotzdem auf eine
        Urheberrechtsverletzung aufmerksam werden, bitten wir um einen entsprechenden Hinweis. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
      </span>
      <span style="margin-top: 10px"
        >Impressum vom Impressum Generator der Kanzlei Hasselbach, Frankfurt</span
      >
    </div>
  </div>
</div>
