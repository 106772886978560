import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SessionService } from 'src/app/services/session/session.service';

@Component({
  selector: 'app-kategorisieren',
  templateUrl: './kategorisieren.component.html',
  styleUrls: ['./kategorisieren.component.scss'],
})
export class KategorisierenComponent {
  zuordnungen: any[] = [];
  ausgewaehlteKarte: any;
  ausgewaehlteKarteZuordnung: any;
  buttonEnabled = false;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly sessionService: SessionService,
    private readonly router: Router,
  ) {
    document.querySelector('html')?.classList.remove('dialog');
  }

  get modul() {
    return this.sessionService.currentModul;
  }
  ngOnInit() {
    document.querySelector('.intro')!.innerHTML = this.replaceAnreden(
      this.modul.schiebereglerIntro,
    );
    this.sessionService.getSessionZuordnungen(this.modul?.id).subscribe((res: any) => {
      this.zuordnungen = res.responseObject;
      this.zuordnungen.forEach((kz: any) => {
        const karte = document.getElementById(kz.karte.kennung);
        karte!.dataset['punkte'] = kz.schieberegler;
        const spalte = document.querySelector(`[data-zuordnung="${kz.dragAndDrop}"]`);
        const ablage = spalte?.querySelectorAll('.ablage')[kz.position - 1];
        this.moveKarte(karte, spalte, ablage);
      });
      this.pruefeAlleAufgabenBearbeitet();
    });
  }

  replaceAnreden(text: string) {
    const spanTemplate = "<span class='text-unterstrichen'>ANREDE</span>";
    const neuText = text.replace('Job', spanTemplate.replace('ANREDE', 'Job'));
    return neuText;
  }

  moveKarte(karte: any, spalte: any, targetAblage: any) {
    const naechsteFreieAblage = Array.from(spalte.querySelectorAll('.ablage')).filter(a =>
      this.isAblageFrei(a),
    )[0];

    const karteNichtInSpalte =
      Array.from(spalte.querySelectorAll('app-mdoul-karte')).filter((k: any) => k.id === karte?.id)
        .length === 0;

    if (targetAblage && this.isAblageFrei(targetAblage)) {
      targetAblage.appendChild(karte);
      karte.dataset.zugeordnet = 'true';
    } else if (
      naechsteFreieAblage !== null &&
      naechsteFreieAblage instanceof HTMLDivElement &&
      karteNichtInSpalte
    ) {
      naechsteFreieAblage.appendChild(karte);
      karte.dataset.zugeordnet = 'true';
    }
  }

  isAblageFrei(ablage: any) {
    return ablage.children.length === 0;
  }

  openKarte(karte: any) {
    this.ausgewaehlteKarte = karte;
    this.ausgewaehlteKarteZuordnung = this.zuordnungen.find(
      (zuordnung: any) => zuordnung.karte.id === karte.id,
    );
    const schieberegler = document.querySelector('app-schieberegler') as HTMLElement;
    document.querySelector('html')?.classList.add('dialog');
    schieberegler.dataset['visible'] = 'true';
  }

  pruefeAlleAufgabenBearbeitet() {
    this.buttonEnabled = this.zuordnungen.filter((zu: any) => zu.schieberegler === -1).length === 0;
  }

  setzePunkte(punkte: any, karte: any) {
    document.getElementById(karte.kennung)!.dataset['punkte'] = punkte;
    this.pruefeAlleAufgabenBearbeitet();
  }

  async themaBeenden() {
    if (this.buttonEnabled) {
      await this.router.navigateByUrl('/uebersicht');
    }
  }
  async zurueck() {
    await this.router.navigateByUrl(`/modul/${this.modul?.id}/bewerten`);
  }
}
