<div class="container">
  <div class="row">
    <div id="karten-ablage" class="column column-20" style="position: relative">
      <app-mdoul-karte
        *ngFor="let karte of modulKarten"
        [id]="karte.kennung"
        draggable="true"
        (dragstart)="drag($event)"
        data-zugeordnet="false"
        data-dialog="false"
        (click)="openKarte(karte)"
        [karte]="karte"
      ></app-mdoul-karte>
      <div class="alle-zugeordnet-text sonder-text" *ngIf="textAnzeigen">
        <span class="text-unterstrichen">Check</span> jetzt noch einmal für dich, ob du etwas
        umsortieren möchtest
      </div>
    </div>
    <div class="column">
      <div class="container">
        <div class="row">
          <div
            class="column spalte"
            *ngFor="let item of [].constructor(5); index as i"
            [attr.data-zuordnung]="i + 1"
          >
            <div class="container">
              <div class="row ablage-header">
                <div class="column">
                  {{ modul?.ordnungsKategorie['prio' + (i + 1)] }}
                </div>
              </div>
              <div class="row" *ngFor="let item of [].constructor(3); let rowIndex = index">
                <div
                  class="ablage"
                  [attr.data-position]="rowIndex + 1"
                  (drop)="drop($event)"
                  (dragover)="dragOver($event)"
                  (dragenter)="dragEnter($event)"
                  (dragleave)="dragLeave($event)"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
