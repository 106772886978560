<div class="content-wrapper">
  <div class="content">
    <div class="container">
      <div class="row">
        <div class="column">
          <h4>So geht’s weiter</h4>
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin: 36px 0 24px 0">
          <span>
            Um gemeinsam herauszufinden, was dir bei der Arbeit besonders wichtig ist, wollen wir
            uns mit dir verschiedene Themenbereiche genauer anschauen. Starte einfach mit dem Thema,
            das dich am meisten anspricht, indem du die entsprechende Karte anklickst. Nachdem du
            ein Thema abgeschlossen hast, landest du automatisch wieder hier und kannst mit dem
            nächsten Thema weitermachen. Viel Spaß beim worXploren...
          </span>
        </div>
      </div>
      <div class="row">
        <div class="column" style="margin-top: 24px">
          <button class="worxplorer-button" (click)="buttonClicked()">Okay</button>
        </div>
      </div>
    </div>
  </div>
</div>
